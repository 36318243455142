@import 'index.scss';

.projects {
    background: $fontdark;
    height: 100%;
    padding-bottom: 70px;
    padding-top: 20px;
    color: $fontlight;
}

.projects h1 {
    margin-top: 0;
    margin-bottom: 50px;
    color: $fontlight;
}
.projects-img {
    width: 300px;
    height: auto;
}

@media screen and (max-width: 768px) {

    .projects h1 {
        font-size: 4em;
        padding-top: 30px;
    }
}