@import 'index.scss';

.header {
  position: fixed;
  width: 100vw;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  z-index: 100;
  background-image: none;
  transition: background 0.4s ease;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, hsla(263, 31%, 78%, 0.5) 0%, hsla(263, 38%, 90%, 0.5) 100%);
    transition: opacity 0.4s ease;
    opacity: 0; 
  }

  &.scrolled-header {
    &::before {
      opacity: 1;
    }
    .logo-img{
      max-width: 130px;
      transition: all 0.7s ease;
    }
  }
}


.logo-img {
  max-width: 200px; 
  z-index: 200;
}

.menu-container {
  display: flex;
  z-index: 999;
  margin-right: 35px;
}

.menu-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  color: $fontdark;
}

.menu-item {
  padding: 10px;
  margin-right: 10px; 
  cursor: pointer;
  font-family: 'Poppins';
  font-weight: 300;
  text-transform: lowercase;
  font-size: 1.5em;
  transition: color 0.2s ease-in;
}

.menu-item:hover {
  color: $plum;
}

.hero {
  height: 100vh;
}
.hero-bg,
.hero-bg-up {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
}

.hero-bg {
  background: rgb(210, 199, 223);
  background: -moz-linear-gradient(180deg, hsl(263, 31%, 78%) 0%, hsl(263, 38%, 90%) 100%);
  background: -webkit-linear-gradient(180deg, hsl(263, 31%, 78%) 0%, hsl(263, 38%, 90%) 100%);
  background: linear-gradient(180deg, hsl(263, 31%, 78%) 0%, hsl(263, 38%, 90%) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d2c7df", endColorstr="#e7dff5", GradientType=1);
  z-index: -10;
}

.hero-bg-up {
  background-image: url('bgUp.png');
  background-repeat: no-repeat;
  background-color: transparent;
  position: relative;
  z-index: 30;
}


.hello {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    margin-top: 50px;
    font-family: Poppins;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.hero-title {
  position: fixed;
  font-size: 10em;
  padding-top: 40px;
  margin: 0;
  background: url(bg.png) bottom;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  z-index: -5;
  opacity: 1;
  transition: opacity 0.5s ease;
}


.hero-title.hidden {
  opacity: 0;
}

.hero-h2 {
  position: relative;
  padding-top: 300px;
  z-index: 45;
  font-size: 4em;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 5px; 
  }

  .menu-container {
    display: none;
  }

  .hero h1 {
    font-size: 6em;
    text-align: center;
  }

  .hero-h2 {
    padding-top: 300px;
    font-size: 2em;
    text-align: center;
  }
}