@import 'index.scss';

.about {
    display: flex;
    flex-direction: row;
    columns: 2;
    background: hsl(270, 28%, 79%);
    background: -moz-linear-gradient(27deg, hsla(270, 28%, 79%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: -webkit-linear-gradient(27deg, hsla(270, 28%, 79%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: linear-gradient(27deg, hsla(270, 28%, 79%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d2c7df",endColorstr="#23266f",GradientType=1);    
    width: 100%;
    z-index: 50;
}

.about img {
    box-shadow: 10px 0 80px #000000b3; 
}

.about h1 {
    color: $fontlight;
    margin: 15px;
}

.about p {
  font-size: 1.2em;
}

.icos {
  font-size: 3em;
  margin: 5px 0 5px 0;
}

.about-text {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify;
}

.dev-img {
    height: 100vh;
    width: auto;
}

@media screen and (max-width: 768px) {
    .about {
      flex-direction: column;
      padding-top: 30px;
    }
  
    .about img {
      box-shadow: none; 
    }

    .about h1 {
        font-size: 6em;
    }
  
    .about-text {
    color: $fontdark;
      padding-left: 15px;
      padding-right: 15px;
    }
  
    .dev-img {
      height: auto;
      width: 100%;
    }
  }