@import 'index.scss';

.more {
    background-color: $fontlight;
    color: $fontdark;
    padding: 35px 25px 5px 25px;
}

.more h1 {
    text-align: center;
    margin-top: 0;
    background: hsl(285, 34%, 78%);
    background: -moz-linear-gradient(90deg, hsla(285, 34%, 78%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: -webkit-linear-gradient(90deg, hsla(285, 34%, 78%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: linear-gradient(90deg, hsla(285, 34%, 78%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d2c7df",endColorstr="#23266f",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.whyus-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.whyus-item {
    display: flex;
    flex-direction: column;
    width: 400px;
    columns: 3;
    align-items: center;
    padding: 0 20px 0 20px;
}

.whyus-item h3 {
    position: relative;
    color: $eminence;
    font-family: 'Epilogue';
    text-transform: lowercase;
    text-align: center;
}

.whyus-item p {
    text-align: center;
    text-wrap: balance;
}

.whyus-img {
    height: 250px;
    width: auto;
    transition: all .5s;
    box-shadow: 2px 2px 10px 2px #00000070;
}

.whyus-img:hover {
    transform: scale(1.09);
}

@media screen and (max-width: 768px) {
    .more {
        padding-top: 20px;
    }

    .whyus-container {
        flex-direction: column;
    }

    .whyus-item {
        width: 100%;
        padding: 0 0 15px 0;
    }

    .more h1 {
        font-size: 3em;
    }

    .whyus-item h3 {
        font-size: 1.2em;
    }

    .whyus-item p {
        font-size: 1em;
    }
}