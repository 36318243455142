@import 'index.scss';

.contact {
    background: $fontlight;
    padding: 100px;
  }
  
  .contact-form-container-header {
    margin: 0 auto;
    padding-left: 20px;
    width: 1100px;
    height: 30px;
    background-color: $fontdark;
    box-sizing: border-box;
    border-radius: 25px 25px 0 0;
  }

  .screen-header-button {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 3px;
    border-radius: 8px;
    background: $fontlight;
  }
  
  .screen-header-button.close {
    background: #ed1c6f;
  }
  
  .screen-header-button.maximize {
    background: #e8e925;
  }
  
  .screen-header-button.minimize {
    background: #74c54f;
  }

  .contact-form-container {
    width: 1100px;
    background: hsl(285, 34%, 78%);
    background: -moz-linear-gradient(130deg, hsla(285, 34%, 78%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: -webkit-linear-gradient(130deg, hsla(285, 34%, 78%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: linear-gradient(130deg, hsla(285, 34%, 78%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d2c7df",endColorstr="#23266f",GradientType=1);
    padding: 20px;
    box-sizing: border-box;
    margin: 0 auto 50px auto;
    font-family: monospace, sans-serif;
    border-radius: 0 0 25px 25px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  .contact-columns {
    display: flex;
    flex-direction: row;
  }

  .contact-form {
    width: 550px;
    padding-top: 30px;
  }

  .contact-text {
    width: 550px;
    margin-left: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
  
  .contact h1 {
    font-size: 5em;
    margin: 15px 0 0 0;
    padding: 0;
  }

  .contact h2, h3 {
    font-family: 'Poppins';
    color: $fontlight;
    position: absolute;
  }
  
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .form-group {
    position: relative;
    margin-bottom: 1.5em;
  }

  input, textarea {
    background-color: transparent;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid $fontlight;
    outline: none;
    color: $eminence;
    transition: border-color 0.3s;
  }

  textarea {
    height: 150px;
    resize: none;
  }

  label {
    font-family: 'Poppins';
    position: absolute;
    top: 0;
    left: 0;
    padding: 5px;
    font-size: 16px;
    color: $eminence;
    pointer-events: none;
    transition: transform 0.3s, color 0.3s;
  }

  input:focus,
  input:valid,
  textarea:focus,
  textarea:valid {
    border-color: $eminence;
  }

  input:focus + label,
  input:valid + label,
  textarea:focus + label,
  textarea:valid + label {
    transform: translateY(-30px);
    font-size: 11px;
    color: $plum;
  }

  .bg-contact {
    background-image: url('./bg-contact.png');
    background-repeat: no-repeat;
    object-fit: cover;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  .captcha {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

  }

  .error-message {
    color:$fontdark;
    font-size: 0.8em;
    font-family: 'Poppins';
    text-align: center;
    position: absolute;
    margin: 0;
    padding: 0;
    transform: translateY(-15px);
  }

  .sending-message {
    color:$fontdark;
    font-size: 1em;
    font-family: 'Poppins';
    text-align: center;
    position: relative;
  }

  button {
    width: 160px;
    font-size: 16px;
    font-weight: 600;
    color: $midnight;
    cursor: pointer;
    margin: 10px;
    height: 45px;
    width: 100%;
    text-align: center;
    border: none;
    background-size: 300% 100%;
    border-radius: 4px;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }

  .inactive {
    filter: grayscale(100%);
    cursor: not-allowed;
  }

  button:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
  }
  button:focus {
    outline: none;
  }
  
  button.bn {
    background-image: linear-gradient(
      to right,
      $fontlight,
      $eminence,
      $plum,
    );
  }

  @media screen and (max-width: 768px) {
    .contact {
      padding: 20px;
    }
  
    .contact-form-container-header {
      width: 100%; 
    }
  
    .contact-form-container {
      width: 100%; 
      padding: 5px; 
    }
  
    .contact-columns {
      flex-direction: column;
    }
  
    .contact-form,
    .contact-text {
      width: 100%;
      margin-left: 0; 
    }


    input, textarea {
      width: 90%;
  
    }
    .contact h1 {
      font-size: 3em;
    }

    button {
      width: 90%;
    }

    .bg-contact {
      border-radius: 25px;
    }
  }
  