@import 'index.scss';

.port {
    background-color: $fontlight;
    color: $fontdark;
    padding: 5px 25px 25px 25px;
    margin-top: 0;
    height: 100%;
}

.port-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.port h1 {
    text-align: center;
    margin-top: 0;
    background: hsl(270, 28%, 79%);
    background: -moz-linear-gradient(310deg, hsla(270, 28%, 79%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: -webkit-linear-gradient(310deg, hsla(270, 28%, 79%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    background: linear-gradient(310deg, hsla(270, 28%, 79%, 1) 0%, hsla(320, 31%, 59%, 1) 50%, hsla(245, 51%, 30%, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d2c7df",endColorstr="#23266f",GradientType=1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.port-item {
    display: flex;
    flex-direction: column;
    columns: 4;
    align-items: center;
    padding: 0 20px 0 20px;
    text-align: center;
    text-wrap: balance;
}

.port-icon {
    color: $plum;
    font-size: 4em;
}

@media screen and (max-width: 768px) {

    .port-container {
        flex-direction: column;
    }

    .port h1 {
        font-size: 3em;
    }

    .port-item {
        padding: 20px 0 0 0;
    }

    .port p {
        font-size: 1em;
    }
}