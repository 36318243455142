@import 'index.scss';

body{
	margin: 0;
	padding: 0;
	background-color: $fontdark;
}

.loader{
	height: 150px;
	width: 150px;
	border: 15px solid $fontlight;
	margin: 200px auto;
	border-radius: 50%;
	border-top: 15px solid $eminence;
	animation: spin 0.5s linear infinite;
}

@keyframes spin{
	100%{
		transform: rotate(360deg);
	}
}