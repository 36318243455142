@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@700&display=swap');

$background: #0a0a0a;
$fontlight: #E7DFF5;
$fontdark: #0a0a0a;
$plum: #9B568A;
$midnight: #23266F;
$eminence: #633B78;

body {
  margin: 0;
  font-family: 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background;
  color: $fontlight;
  overflow-x: hidden;
}


a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

h1 {
  font-family: 'Epilogue';
  font-weight: 700;
  text-transform: lowercase;
  font-size: 9em;
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
}